<!--
 * @FilePath: \zsw_new_edition\src\views\personalCenter\myAccountSet.vue
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2022-01-11 10:24:10
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2022-02-16 15:44:43
-->
<template>
  <div>
    <div class="head">
      <img src="../../assets/image/bg/myAccountSet.png" alt="" class="bckImg" />
      <div class="headLeft">
        <el-upload
          class="avatar-uploader"
          :show-file-list="false"
          :auto-upload="true"
          :action="$ROOT_DIR + '/user/Lists/uploadPic?token=' + token"
          accept="image/png,image/jpg,image/jpeg"
          :on-success="handleAvatarSuccess"
        >
          <img v-if="user.photo" :src="user.photo" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <div class="userInfo">
          <div class="userInfoTop">
            <p v-if="!inputStatus">{{ user.nickname }}</p>
            <el-input
              v-model="input"
              v-focus
              v-else
              @blur="changeName"
            ></el-input>
            <img
              class="sidebarUserEditor"
              src="@/assets/image/icon/editor-icon.png"
              alt=""
              @click="inputStatus = true"
            />
          </div>
          <p>ID：{{ user.uid }}</p>
        </div>
      </div>
      <div class="headRight">
        <div v-if="userInfo.is_student == 1" class="isStudent">
          <div>已认证学生版</div>
        </div>
        <div v-else class="noStudent">
          <p>认证学生版，购会员/线下课程享8折优惠等</p>
          <router-link :to="{ path: '/student/index' }" target="_blank"
            ><div>立即认证</div></router-link
          >
        </div>
        
      </div>
    </div>
    <div class="content">
      <p style="margin-bottom:24px">个人简介</p>
      <div class="textareaBox">
        <el-input
          type="textarea"
          placeholder="请填写您个人主页的简介"
          v-model="textarea"
          maxlength="250"
          resize="none"
          @blur="changeAbout"
        >
        </el-input>
        <div class="submit" :class="{ submit1: textareaStatus }">确定</div>
      </div>
      <div class="rowBox">
        <div>
          <p>手机</p>
          <img
            src="../../assets/image/icon/finish.png"
            alt=""
            v-if="user.user_name"
          />
          <img src="../../assets/image/icon/tips.png" alt="" v-else />
          <span v-if="user.user_name"
            >已绑定：{{
              user.user_name.substr(0, 3) +
              "****" +
              user.user_name.substr(7)
            }}</span
          >
          <span v-else>未绑定</span>
        </div>
        <p
          class="rowBoxBtn"
          v-if="user.user_name"
          @click="cancelBind('tel')"
        >
          解除绑定
        </p>
        <p class="rowBoxBtn" v-else @click="bindTel()">立即绑定</p>
      </div>
      <div class="rowBox">
        <div>
          <p>微信</p>
          <img
            src="../../assets/image/icon/finish.png"
            alt=""
            v-if="user.wx_is_bind"
          />
          <img src="../../assets/image/icon/tips.png" alt="" v-else />
          <span>{{ user.wx_is_bind ? "已绑定" : "未绑定" }}</span>
        </div>
        <p
          class="rowBoxBtn"
          v-if="user.wx_is_bind"
          @click="cancelBind('wx')"
        >
          解除绑定
        </p>
        <p class="rowBoxBtn" v-else @click="bindWx()">立即绑定</p>
      </div>
      <div class="rowBox">
        <div>
          <p>账号密码</p>
          <img
            src="../../assets/image/icon/finish.png"
            alt=""
            v-if="user.user_name"
          />
          <img src="../../assets/image/icon/tips.png" alt="" v-else />
          <span>{{ user.user_name ? "已设置" : "未设置" }}</span>
        </div>
        <p class="rowBoxBtn" v-if="user.user_name" @click="changePwd()">
          更改密码
        </p>
        <p class="rowBoxBtn" v-else @click="szPwd()">设置密码</p>
      </div>
    </div>
    <el-dialog
      :visible.sync="centerDialogVisible"
      center
      :destroy-on-close="true"
      :show-close="false"
    >
      <div class="secretBox">
        <div class="headBox">
          <p>{{ type == "tel" ? "解除手机号绑定" : "解除微信绑定" }}</p>
          <img
            src="../../assets/image/icon/close.png"
            alt=""
            @click="centerDialogVisible = false"
          />
        </div>
        <p class="infoP">
          {{
            type == "tel"
              ? "解绑后将无法使用该手机号登录此账号，谨慎操作！"
              : "解绑后将无法使用该微信号登录此账号，谨慎操作！"
          }}
        </p>
        <div class="btnBox">
          <div @click="type == 'wx' ? unBindWx() : unBindTel()">解除绑定</div>
          <div @click="centerDialogVisible = false">暂不解绑</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getToken, setToken } from "../../utils/auth";
import { getMyInfo, editMyInfo, wxUnBind, telUnBind } from "../../Api";
import { mapGetters } from "vuex";
export default {
  name: "MyAccountSet",
  data() {
    return {
      centerDialogVisible: false,
      token: getToken(),
      user: {},
      input: "",
      textarea: "",
      inputStatus: false,
      textareaStatus: false,
      type: "tel",
    };
  },
  created() {
    this.initData();
  },
  methods: {
    cancelBind(type) {
      if (this.user.user_name && this.user.wx_is_bind) {
        this.type = type;
        this.centerDialogVisible = true;
      } else {
        this.$message.error("唯一登录方式，无法解绑");
      }
    },
    szPwd() {
      this.$message.error("请先绑定手机号");
    },
    changePwd() {
      this.$changePwdPop().show(this.user.user_name);
    },
    initData() {
      //获取用户信息
      this.getMyInfo(this.token);
    },
    //修改昵称
    changeName() {
      this.inputStatus = false;
      //保存信息
      if (this.user.nickname != this.input && this.input) {
        this.user.nickname = this.input;
        this.user.token = this.token;
        this.saveInfo();
      } else {
        this.$message.error("修改失败");
      }
    },
    //修改简介
    changeAbout() {
      this.textareaStatus = false;
      //保存信息
      if (this.user.about != this.textarea) {
        this.user.about = this.textarea;
        this.user.token = this.token;
        this.saveInfo();
      }
    },
    //保存信息
    saveInfo() {
      editMyInfo(this.user).then((res) => {
        if (res.data.message === "update_success") {
          this.$message.success("修改成功");
          this.$store.dispatch("user/getInfo");
        } else {
          this.$message.error("修改失败");
        }
      });
    },
    //获取用户信息
    getMyInfo(token) {
      getMyInfo(token).then((res) => {
        if (res.status === "200") {
          this.user = res.data;
          this.input = this.user.nickname;
          this.textarea = this.user.about;
          this.user.uid = this.uid;
        } else {
          this.$login({ isRouter: false });
        }
      });
    },
    //上传成功
    handleAvatarSuccess(res) {
      if (res.data.message == "uploadSuccess") {
        this.user.photo = res.data.filePath;
        this.user.token = this.token;
        this.saveInfo();
      }
    },
    bindWx() {
      this.$wxPop().show();
    },
    //微信解绑
    unBindWx() {
      wxUnBind({ token: this.token }).then((res) => {
        if (res.data.msg === "success") {
          this.$message.success("解绑成功");
          window.location.reload();
        } else {
          this.$message.error("解绑失败");
        }
      });
    },
    //手机号解绑
    unBindTel() {
      telUnBind({ token: this.token }).then((res) => {
        if (res.data.msg === "success") {
          this.$message.success("解绑成功");
          setToken(res.data.token);
          window.location.reload();
        } else {
          this.$message.error("解绑失败");
        }
      });
    },
    bindTel() {
      this.$bindTelPop().show();
    },
  },
  computed: {
    ...mapGetters(["uid", "userInfo"]),
  },
  directives: {
    focus: {
      inserted: function (el) {
        el.querySelector("input").focus();
      },
    },
  },
  watch: {
    textarea(val, old) {
      if (val != old && old == this.user.about) {
        this.textareaStatus = true;
      }
    },
  },
};
</script>

<style scoped>
::v-deep .el-textarea__inner {
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 100%;
  height: 1.2rem;
  font-size: inherit;
  color: #606266;
  background-color: #fff;
  background-image: none;
  border: 0px solid #dcdfe6;
  border-radius: 4px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  background: #f7f6fb;
}
::v-deep .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 0.28rem;
  line-height: 0.28rem;
  outline: 0;
  padding: 0 0.15rem;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
.head {
  width: 100%;
  height: 1.2rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.headLeft {
  height: 0.6rem;
  display: flex;
  padding-left: 0.3rem;
}
.headLeft img {
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  margin-right: 0.1rem;
}
.headRight .noStudent {
  display: flex;
  margin-right: 0.3rem;
  align-items: center;
}
.headRight .noStudent p {
  font-size: 0.14rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  opacity: 1;
  margin-right: 0.2rem;
}
.headRight .noStudent div {
  width: 1.16rem;
  height: 0.36rem;
  background: linear-gradient(180deg, #18c5fd 0%, #3663fd 100%);
  opacity: 1;
  border-radius: 0.18rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.14rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  opacity: 1;
  cursor: pointer;
}
.headRight .isStudent {
  display: flex;
  margin-right: 0.3rem;
  align-items: center;
}
.headRight .isStudent div {
  width: 1.16rem;
  height: 0.36rem;
  background: #fef4e2;
  opacity: 1;
  border-radius: 0.18rem;
  font-size: 0.14rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #f46600;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.userInfo {
  margin-top: 0.04rem;
}
.userInfo p:nth-child(1) {
  font-size: 0.2rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 0.06rem;
}
.userInfo p:nth-child(2) {
  font-size: 0.14rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  opacity: 1;
}
.bckImg {
  position: absolute;
  width: 100%;
  height: 1.2rem;
  z-index: -1;
}
.userInfoTop {
  display: flex;
}
.userInfoTop img {
  height: 0.28rem;
  width: 0.28rem;
  opacity: 1;
  margin-left: 0.22rem;
}
.content {
  width: 100%;
  /*height: 6.2rem;*/
  background: #ffffff;
  opacity: 1;
  border-radius: 4px;
  margin-top: 0.1rem;
  padding: 0.3rem 0.3rem 0 0.3rem;
}
.content p {
  font-size: 0.16rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #040404;
  opacity: 1;
}
.content p:nth-child(1) {
  /*margin-bottom: 0.2rem;*/
}
.textareaBox {
  position: relative;
}
.submit {
  width: 0.72rem;
  height: 0.32rem;
  background: #d9d9d9;
  opacity: 1;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.12rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  opacity: 1;
  position: absolute;
  right: 0.15rem;
  bottom: 0.15rem;
}
.submit1 {
  background: #f46600;
}
.rowBox {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
}
.rowBox:not(:last-child) {
  border-bottom: 1px solid #d9d9d9;
}
.rowBox div {
  display: flex;
}
.rowBox div p:nth-child(1) {
  margin-right: 1rem;
}
.rowBox:last-child div p:nth-child(1) {
  margin-right: 0.66rem !important;
}
.rowBox div img {
  width: 0.2rem;
  height: 0.2rem;
  margin-right: 0.3rem;
}
.rowBoxBtn {
  margin-right: 0.62rem;
  font-size: 0.14rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #f46600 !important;
  opacity: 1;
  cursor: pointer;
}

.secretBox {
  margin: 0 auto;
  border-radius: 8px;
  position: relative;
  width: 384px;
  height: 182px;
  background: #ffffff;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  padding: 20px 30px 0 30px;
}
.secretBox p:nth-child(1) {
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #040404;
  line-height: 22px;
  margin-bottom: 20px;
}
.secretBox p:nth-child(2) {
  width: 324px;
  height: 34px;
  font-size: 12px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 17px;
  margin-bottom: 30px;
}

::v-deep .el-button--primary:first-child {
  width: 120px;
  background-color: #ffffff;
  border-color: #d9d9d9;
  color: #999999;
}
::v-deep .el-button--primary:last-child {
  width: 120px;
  background-color: #ff9e58;
  border-color: #ff9e58;
}

::v-deep .el-dialog {
  background: rgba(0, 0, 0, 0);

  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0);

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0);
}
.secretBox .headBox {
  display: flex;
  justify-content: space-between;
}
.secretBox .headBox img {
  width: 16px;
  height: 16px;
}
.secretBox .infoP {
  width: 322px;
  height: 20px;
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #040404;
  line-height: 20px;
}
.btnBox {
  width: 218px;
  height: 40px;
  display: flex;
  float: right;
  justify-content: space-between;
}
.btnBox div {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.btnBox div:nth-child(1) {
  width: 94px;
  height: 40px;
  background: #f0f3f4;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  font-size: 14px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #999999;
  line-height: 20px;
}
.btnBox div:nth-child(2) {
  width: 94px;
  height: 40px;
  background: #ff9e58;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  font-size: 14px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #ffffff;
  line-height: 20px;
}
</style>